@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat400";
  src: url(../fonts/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat800";
  src: url(../fonts/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat600";
  src: url(../fonts/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat700";
  src: url(../fonts/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat500";
  src: url(../fonts/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat300";
  src: url(../fonts/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway400";
  src: url(../fonts/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway800";
  src: url(../fonts/Raleway-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway600";
  src: url(../fonts/Raleway-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway700";
  src: url(../fonts/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway500";
  src: url(../fonts/Raleway-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway300";
  src: url(../fonts/Raleway-Light.ttf) format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: auto !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

input:focus {
  outline: none;
}

.activeElement {
  background-color: #040825 !important;
  opacity: 1 !important;
}

.activeElemnetDrawer {
  background-color: #fff;
  position: relative;

  color: #000 !important;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #60EBEB;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.scrollTableY {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollTableX {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}